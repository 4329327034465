<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2022-01-21 14:45:15
 * @LastEditors: ------
 * @LastEditTime: 2022-02-21 10:12:10
-->
<template>
    <div class="register">
        <van-nav-bar title="注册" @click-left="$router.go(-1)" left-arrow safe-area-inset-top placeholder />
        <div class="registerType">

            <van-row style="margin: 26px">
                <van-col span="2"></van-col>
                <van-col span="20">
                    <van-form @submit="onSubmit()" class="myForm">
                        <van-field v-model="realName" placeholder="请填写真实姓名" autofocus clearable center size="large"
                            autocomplete label-width="2rem" :left-icon="require('../../images/icon-tx.png')"
                            :rules="[{ required: true }]" />
                        <van-field v-model="mobile" placeholder="请填写手机号" autofocus clearable center size="large"
                            autocomplete label-width="2rem" :left-icon="require('../../images/icon-sj.png')"
                            :rules="[{ required: true }]" />

                        <van-cell-group>
                            <van-field v-model="verifyCode" center clearable placeholder="请输入验证码" autocomplete
                                label-width="2rem" :left-icon="require('../../images/icon-yzm.png')"
                                :rules="[{ required: true }]">
                                <template #button>
                                    <van-button size="small" round block type="info" class="button2" v-if="show"  v-model="value"
                                        @click="onAuthCode">点击获取
                                    </van-button>
                                    <van-button size="small" round block type="info"   disabled class="button2" v-else >
                                            {{value}}
                                    </van-button>
                                </template>
                            </van-field>
                        </van-cell-group>

                        <van-field v-model="pwd" @blur="text" type="password" placeholder="请填写密码" clearable autocomplete
                            center label-width="2rem" size="large" :left-icon="require('../../images/icon-mm.png')"
                            :rules="[{ required: true }]" />
                        <van-field v-model="confirmPwd" type="password" placeholder="请确认密码" clearable autocomplete
                            @blur="text2" center label-width="2rem" size="large"
                            :left-icon="require('../../images/icon-mm.png')" :rules="[{ required: true }]" />
                        <div class="button">
                            <van-button round block type="info" native-type="submit">注册</van-button>
                        </div>
                    </van-form>
                </van-col>
                <van-col span="2"></van-col>
            </van-row>


        </div>
    </div>
</template>

<script>
import { verifyCode, register, checkUserName } from "@/api";

import { Notify } from "vant";
import router from "../../router";
export default {
    name: "",
    data() {
        return {
            realName: "",
            mobile: "",
            pwd: "",
            confirmPwd: "",
            verifyCode: "",
            timer: '',
            countdown: 60,
            show: true,
            value:""
        };
    },
    onUnload() {
        clearInterval(this.timer)
    },
    methods: {
        //密码判断
        text() {
            if (!(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/.test(this.pwd))) {
                Notify({
                    type: "danger",
                    message: "密码为8到20位包含数字和字母的字符串组成,不存在特殊字符",
                    duration: 2000,
                });
                 return 
            }
             return true
        },
        //确认密码判断
        text2() { 
            if (!(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/.test(this.confirmPwd))) {
                Notify({
                    type: "danger",
                    message: "密码为8到20位包含数字和字母的字符串组成,不存在特殊字符",
                    duration: 2000,
                });
                return 
            } else
                if (this.pwd != this.confirmPwd) {
                    Notify({
                        type: "danger",
                        message: "密码不一致！",
                        duration: 2000,
                    });
                    return
                }
                return true
        },
        //提交
        onSubmit() {
            if(!this.text()){
                  return  
            }
           if(!this.text2()){
                  return  
            }
            let formData =
            {
                realName: this.realName,
                mobile: this.mobile,
                pwd: this.pwd,
                confirmPwd: this.confirmPwd,
                verifyCode: this.verifyCode
            }
            register(formData).then((res) => {
                if (res.data.code == 200) {
                    Notify({
                        type: "success",
                        message: "注册成功",
                        duration: 1000,
                    })
                    this.$router.push("/login");
                }
                if (res.data.code == 500) {
                    Notify({
                        type: "danger",
                        message: res.data.message,
                        duration: 1000,
                    });
                }
            })

        },
        //验证码判断
        onAuthCode() {
            if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.mobile))) {
                Notify({
                    type: "warning",
                    message: "请输入11位有效手机号",
                    duration: 1000,
                })
                return
            }
            //   let res =  await getCode({mobile :this.mobile});
            checkUserName(this.mobile).then((res) => {
                console.log(res)
                if (res.data.code == 500 || res.data.message == "手机号已经注册") {
                    Notify({
                        type: "danger",
                        message: "手机号已经注册",
                        duration: 1000,
                    })
                } else {
                    const TIME_COUNT = 60
                    verifyCode({ mobile: this.mobile });

                    this.timer = setInterval(() => {
                        if (this.countdown == 0) {
                            this.show = true
							this.value = "重新获取验证码"
                            clearInterval(this.timer)
                        } else if (this.countdown > 0 && this.countdown <= TIME_COUNT) {
                            this.show = false
                            this.countdown--;
                            this.value = this.countdown + "s";
                        }
                    }, 1000)
                }
            })

            // const res = await verifyCode({
            //     mobile: this.mobile,
            // });


        },
    },
};
</script>

<style scoped lang="less">
button {
    cursor: pointer;
    background: linear-gradient(-90deg, #5038FA, #1476FF);
}

.van-nav-bar {
    background: linear-gradient(-90deg, #5038FA, #1476FF);
}

/deep/ .van-nav-bar__title {
    color: white;
    font-size: 0.45rem;
}

.register {
    .registerType {
        margin-top: 20px;
        font-size: 18px;

        .myForm {
            margin-top: 30px;

            /deep/ .van-field {
                height: 80px;
                font-size: 20px;

                .van-icon {
                    width: 22px;
                    height: 22px;

                    .van-icon__image {
                        width: 100%;
                        height: 100%;
                    }
                }

                .van-field__label {
                    font-size: 20px;
                }

                .van-cell-group--inset {
                    margin: 0px;
                }
            }

        }
    }

    .button {
        margin-top: 30px;

        /deep/ .van-button__text {
            font-size: 30px;
        }
    }

    .button2 {
        width: 68px;
        font-weight: bold;
        height: 24px;
        background: white;
        Color: #4731FA;
        text-align: center;
        border: 1px solid #4731FA;
        border-radius: 12px;
    }
}
</style>
